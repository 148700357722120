import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/connatix-logo.svg'

import styles from './Navigation.module.scss';
import './hamburgers/hamburgers.scss'
import MobileMenu from './components/MobileMenu/MobileMenu';
import DesktopMenu from "./components/DesktopMenu/DesktopMenu";

const navigationLinks = [
  {
    category: 'About us',
    categoryRef: 'about-us'
  },
  {
    category: 'FAQ',
    categoryRef: 'faq'

  },
  {
    category: 'Partner with us',
    categoryRef: 'partner-with-us'

  },
  {
    category: 'Get in touch',
    categoryRef: 'homepage-contact-form'

  },
  
];


const Navigation = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  // Classes for Hamburger Menu Icon
  const menuToggleClasses = [
    'hamburger',
    'hamburger--slider',
    styles['navbar__menu-toggle']
  ];

  // Toggle Hamburger Menu Icon
  if (isMobileNavOpen) {
    menuToggleClasses.push('is-active');
  }

  const toggleMobileNav = () => {
    isMobileNavOpen ? setIsMobileNavOpen(false) : setIsMobileNavOpen(true);
    const bodyElem = document.getElementsByTagName("body");
    const htmlElem = document.getElementsByTagName('html');
    htmlElem[0].classList.toggle('body--overflow');
    bodyElem[0].classList.toggle('body--overflow');
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
    const bodyElem = document.getElementsByTagName("body");
    const htmlElem = document.getElementsByTagName('html');
    htmlElem[0].classList.remove('body--overflow');
    bodyElem[0].classList.remove('body--overflow');
  };

  return (
    <nav className={styles['navbar']}>
      <div className={styles['navbar-logo-container']}>
        <div className={menuToggleClasses.join(' ')} onClick={toggleMobileNav}>
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
        </div>
        <Link to="/" onClick={closeMobileNav} className={styles['logo-container']}>
          <img src={logo} alt="Connatix" />
        </Link>
      </div>

      {isMobileNavOpen ?
        <MobileMenu navigationLinks={navigationLinks} closeMobileNav={closeMobileNav} />
        :
        undefined
      }
      <DesktopMenu navigationLinks={navigationLinks} />
      <div className={styles['navbar-user-actions']}>
        <a href="https://connatix.com"
          rel="noreferrer noopener"
          target="_blank"
          className={styles['navbar__demo-btn']}>Visit connatix.com</a>
      </div>
    </nav>
  )
};

export default Navigation;