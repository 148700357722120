import React from 'react';
import PropTypes from 'prop-types';

import styles from './ContactFormSection.module.scss';

import Container from "../../hoc/Container/Container";
import ContactForm from "../ContactForm/ContactForm";

const ContactFormSection = ({id, title, titleDecorationColor, description, formID, formDecoration, pricingPlan}) => {
  const titleDecorationStyles = {
    backgroundColor: titleDecorationColor,
    height: '4px',
    margin: '20px 0 16px',
    width: '115px'
  };

  return (
    <section className={styles['contact-form-section']} id={id}>
      <Container>
        <div className={styles['contact-form-section-content']}>
          <div style={titleDecorationStyles}/>
          <h3 className={styles['contact-form-section__title']}>{title}</h3>
          <p className={styles['contact-form-section__description']}>{description}</p>
        </div>
        <ContactForm formID={formID} formDecoration={formDecoration} pricingPlan={pricingPlan} />
      </Container>
    </section>
  );
};

ContactFormSection.propTypes = {
  title: PropTypes.string.isRequired,
  titleDecorationColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
  pricingPlan: PropTypes.string
};

export default ContactFormSection;