import React, { Component } from 'react';
import { Link } from "react-scroll/modules";
import "../../../styles/Header.scss";


import headerBkg from "../../../../assets/homepage/advertisers_header_background.png";
import Header from "../../../../hoc/Header/Header";

class HomepageHeader extends Component {

  
    componentDidMount() {
        // Adding our Video Player Unit
        const script = document.createElement("script");
        script.id = "550ab7e3937c4e5dbe9e18218cf9a071";
        script.innerHTML = (`cnx.cmd.push(function() { cnx({ playerId: "d9d6205a-1cac-4b98-a9a6-d9dabd69c954"}).render("550ab7e3937c4e5dbe9e18218cf9a071");});`);
        const playerContainer = document.getElementsByClassName('header-content__player')[0];
        playerContainer.appendChild(script);
    }
  
    render() {
      return (
        <Header backgroundImage={headerBkg}>
          <div className='header-content' id="about-us">
          <div className='header-content__info-container' >
              <div className='header-content__info'>
                <h2 className='header-content__title'>Video. Reimagined.</h2>
                <h1 className='header-content__description'>Connatix is a video technology platform built for publishers and advertisers. We believe in the power of engaging content and are on a mission to help publishers deliver successful video experiences.</h1>
                <Link to="homepage-contact-form"
                      spy={true}
                      smooth={true}
                      className='header-content__btn'>
                  Get in touch
                </Link>
              </div>
            </div>
            <div className='header-content__player-container'>
              <div className='header-content__player-background'>
                <div className='header-content__player'>
                </div>
              </div>
            </div>
          </div>
        </Header>
      )
    }
}

export default HomepageHeader;