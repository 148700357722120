import React, { Component } from 'react';
import styles from './Footer.module.scss';
import Container from "../../hoc/Container/Container";
import { Link } from "react-scroll/modules";

import logo from '../../assets/footer/connatix-logo.svg';


class Footer extends Component {

  setHeaderMenuActive = (headerMenuCategory) => {
    const currentActiveItem = document.getElementsByClassName('menu-header-active')[0];
    if (currentActiveItem) {
      currentActiveItem.classList.remove('menu-header-active');
    }
    const newActiveItem = document.getElementById(headerMenuCategory);
    if (newActiveItem) {
      newActiveItem.classList.add('menu-header-active');
    }
  };

  render() {
    return (
      <footer className={styles['footer']}>
        <Container>
          <div className={styles['footer-content']}>
            <div className={styles['footer-links-container']}>
              <div className={styles['footer-logos']}>
                <img src={logo}
                  className={styles['connatix-logo']}
                  alt="Connatix" />

              </div>
              <div className={styles['footer-middle']}>

                <div className={styles['footer-menu']}>
                  <nav className={styles['footer-menu__navigation']}>
                    <ul className={styles['footer-menu__section']}>

                      <li className={styles['footer-menu__section__title']} onClick={() => this.setHeaderMenuActive("About us")}>

                        <Link to="about-us"
                          spy={true}
                          smooth={true}
                          className={styles['footer-menu__link']}>
                          About us
                        </Link>

                      </li>
                    </ul>
                    <ul className={styles['footer-menu__section']}>

                      <li className={styles['footer-menu__section__title']} onClick={() => this.setHeaderMenuActive("faq")}>
                        <Link to="faq"
                          spy={true}
                          smooth={true}
                          className={styles['footer-menu__link']}>
                          FAQ
                        </Link>
                      </li>

                    </ul>
                    <ul className={styles['footer-menu__section']}>
                      <li className={styles['footer-menu__section__title']} onClick={() => this.setHeaderMenuActive("Partner with us")}>


                        <Link to="partner-with-us"
                          spy={true}
                          smooth={true}
                          className={styles['footer-menu__link']}>
                          Partner with us
                        </Link>

                      </li>
                    </ul>
                    <ul className={styles['footer-menu__section']}>
                      <li className={styles['footer-menu__section__title']} onClick={() => this.setHeaderMenuActive("Partner with us")}>

                        <Link to="homepage-contact-form"
                          spy={true}
                          smooth={true}
                          className={styles['footer-menu__link']}>
                          Get in touch
                        </Link>
                      </li>
                    </ul>
                    <ul className={styles['footer-menu__section']}>
                      <li className={styles['footer-menu__section__title']} onClick={() => this.setHeaderMenuActive("Privacy")}>
                        <a href="https://www.connatix.com/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles['footer-menu__link']}>
                          Privacy
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>


            <div className={styles['footer__copyright']}>&copy; {new Date().getFullYear()} Connatix Holdings Inc., All Rights Reserved.</div>
          </div>
        </Container>
      </footer>
    )
  }
}

export default Footer;