
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import CustomCookiesConsent from './components/CustomCookieConsent/CustomCookiesConsent';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Footer from './layout/Footer/Footer';
import Navigation from './layout/Navigation/Navigation';
import HomePage from './pages/home';
import styles from './App.module.scss';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <CustomCookiesConsent/>
        <Navigation/>
        <div className={styles['app-container']}>
            <Switch>
            <Redirect from="/index.html" exact to="/" />
            <Route path="/" exact component={HomePage} />

            </Switch>
        </div>
        <Footer/>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
