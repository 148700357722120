import React from "react";
import ContactFormSection from "../../components/ContactFormSection/ContactFormSection";
import formDecoration from '../../assets/contact/contact-form-decoration-black.png';
import Head from "../../layout/Head/Head";
import HomepageHeader from "./components/HomepageHeader/HomepageHeader";
import CommonQuestions from "../../components/CommonQuestions/CommonQuestions";
import connatixSiteImg from "../../assets/connatix-site.png"
import styles from "./index.module.scss"
const HomePage = () => {
    return (
        <div>
            <Head title="Connatix" />
            <HomepageHeader />
            <main>
                <CommonQuestions />
                <div className={styles['connatix-site-section']} id="partner-with-us">
                    <div>
                        <h2>Interested in partnering with Connatix?</h2>
                 
                        <p>Check out our website to learn more.</p>
                        <a href="https://connatix.com"
                            rel="noreferrer noopener"
                            target="_blank"
                            className={styles['navbar__demo-btn']}>Visit connatix.com</a>
                    </div>

                    <div>
                        <img src={connatixSiteImg} alt="connatix site" />
                    </div>

                </div>
                <ContactFormSection
                    id="homepage-contact-form"
                    title="Get in touch"
                    titleDecorationColor='#FFCC00'
                    description=""
                    formID="1513"
                    formDecoration={formDecoration}
                />
            </main>
        </div>

    );
};

export default HomePage;