import React, { Fragment } from 'react';

import './MobileMenu.scss';
import { Link } from "react-scroll/modules";

const MobileMenu = (props) => {

  const toggleMenuSection = e => {
    e.currentTarget.nextElementSibling.classList.toggle('mobile-menu__section--hidden');
    e.currentTarget.children[1].classList.toggle('mobile-menu__header__expand-icon--closed');
  };

  return (
    <div className='mobile-menu-container'>
      <div className='mobile-menu'>
        {props.navigationLinks.map((item, index) => (
          <Fragment key={index}>
            <div className='mobile-menu__header' onClick={toggleMenuSection}>
              <span className='mobile-menu__header__text'>{item.category}</span>
              <div className='mobile-menu__header__expand-icon'>
                <i className="material-icons">
                  expand_more
                </i>
                <i className="material-icons">
                  expand_less
                </i>
              </div>
            </div>
            <ul className='mobile-menu__section'>

              <li key={index} >
                <Link onClick={props.closeMobileNav} to={item.categoryRef} spy={true}
                  smooth={true} className='mobile-menu__link' activeClass='mobile-menu__link--active'>
                  {item.category}
                </Link>
              </li>

            </ul>
          </Fragment>
        ))}
        <div className={'mobile-demo-btn-container'}>
          <a href="https://connatix.com"
            rel="noreferrer noopener"
            target="_blank"
            className={'mobile-demo-btn'} onClick={props.closeMobileNav}>Visit connatix.com</a>

        </div>


      </div>
    </div>
  );
};

export default MobileMenu;